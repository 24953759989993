.ant-steps-item-finish .ant-steps-item-icon {
    background: #088ED7 !important;
}
.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon{
    color: white !important;
}
/*.ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title {*/
/*    font-weight: 700 !important;*/
/*}*/
.ant-steps-item-process .ant-steps-item-content .ant-steps-item-title {
    font-weight: 700 !important;
}
/*.ant-steps-item-wait .ant-steps-item-content .ant-steps-item-title {*/
/*    font-weight: 700 !important;*/
/*}*/