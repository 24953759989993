.ag-theme-alpine {
    /* disable all borders */
    --ag-borders: none;
    /* then add back a border between rows */
    --ag-row-border-style: solid;

    /* accent colour used for checked checkboxes, range selections, row hover, row selections, selected tab underlines, and input focus outlines in the Alpine theme */
    /*--ag-alpine-active-color: #fafafa;*/
    /* Colour of text and icons in primary UI elements like menus */
    --ag-foreground-color: #181D1F;
    /* Background colour of the grid */
    --ag-background-color: #FFFFFF;
    /* Colour of text and icons in UI elements that need to be slightly less emphasised to avoid distracting attention from data */
    --ag-secondary-foreground-color: #565656;
    /* Colour of text in grid cells */
    --ag-data-color: #181D1F;
    /* Colour of text and icons in the header */
    --ag-header-foreground-color: #181D1F;
    /* Background colour for second level headings within UI components */
    --ag-header-background-color: #FAFAFA;
    /* Background colour applied to every other row */
    --ag-odd-row-background-color: #FFFFFF;
    /* Background color when hovering over rows in the grid and in dropdown menus. */
    --ag-row-hover-color: #FAFAFA;
    /* Colour for border around major UI components like the grid itself, headers; footers and tool panels. */
    --ag-border-color: #F0F0F0;

    --ag-font-size: 14px;
    --ag-font-family: Roboto;

    /* Sizes */
    --ag-cell-horizontal-padding: 16px;
    --ag-row-height: 48px;
    --ag-header-height: 56px;

    --ag-header-column-separator-display: 'block';
    --ag-header-column-separator-height: 22px;
    --ag-header-column-separator-width: 1px;
    --ag-header-column-separator-color: #F0F0F0;

    --ag-range-selection-border-style: 'none';
}

.ag-theme-alpine .ag-header-cell {
    font-weight: 500;
    /*font-size: 14px;*/
}

.ag-theme-alpine .ag-cell {
    font-weight: 400;
}